import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay, EffectFade } from 'swiper/modules';



// Get all elements with class .swiper
const swiperElements = document.querySelectorAll('.swiper');

swiperElements.forEach(element => {

    // Different configs based on data-attribute
    const swiperType = element.dataset.swiperType;
    let config;

    switch (swiperType) {
        case "backgroundFade":
            config = {
                modules: [Autoplay, EffectFade],
                loop: true,
                effect: "fade",
                fadeEffect: {
                    crossFade: true,
                },
                speed: 2000,
                autoplay: {
                    delay: 4000,
                    disableOnInteraction: false,
                }
            };
            break;

        default:
            config = {
                modules: [Navigation, Pagination],
                pagination: {
                    el: ".swiper-pagination",
                    type: "progressbar",
                },
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                // Optional parameters
                slidesPerView: 1.3,
                spaceBetween: 24,
                loop: false,
                grabCursor: true,
                breakpoints: {
                    640: {
                      slidesPerView: 2.3,
                    },
                    768: {
                      slidesPerView: 3.3,
                    },
                    1240: {
                      slidesPerView: 4.2,
                    },
                },
            }
            break;
    }

    const swiper = new Swiper(element, config);

})
